.timelineBlock {
  position: relative;
}

.timelineBlock__icon {
  position: absolute;
  top: 30px;
  right: 2px;
}

.timelineBlock__icon svg {
  fill: #e3b500;
}
