.articleFilter
    display: flex
    margin-left: 20px
    width: calc(100% - 20px)
    flex-flow: row wrap
    margin-top: -10px
    @media (max-width: 700px)
        display: block
    > div
        margin-top: 10px
        margin-right: 5px
        min-width: 200px
        @media (max-width: 700px)
            margin-right: 20px
            margin-bottom: 7px

.articleCategory
    display: flex
    margin-left: 20px
    margin-right: 20px
    margin-top: 5px
    width: calc(100% - 40px)
    &__fixer
        overflow: hidden
        height: 39px
        display: block
        width: 100%
    &__scroll
        height: 89px
        overflow-x: scroll
        overflow-y: hidden
        position: relative
    &__items
        display: flex
        flex-flow: row nowrap
        width: auto
        position: absolute
        top: 0
        left: 0

.articleList
    padding: 0px 20px
    margin-bottom: 20px
    h2, h3, h4
        margin: 0
        padding: 0
    h2
        font-size: 25px
        border-bottom: solid 1px #e2e0e0
        padding-bottom: 10px
        margin: 20px 0 15px 0
    h3
        font-size: 20px
        margin-bottom: 15px
    h4
        font-size: 18px
        padding-bottom: 5px
        @media (max-width: 1110px)
            padding-bottom: 10px
    &__block
        background: #fff
        padding: 20px 20px
        border-radius: 3px
    &__tablerWraper
        max-width: 100%
        overflow-x: scroll
        overflow-y: scroll
    &__table
        position: relative
    &__list
        position: relative

.article
    display: flex
    flex-flow: column
    padding: 15px
    width: 100%
    background: #fff
    margin-bottom: 15px
    border-radius: 4px
    overflow: hidden
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.04)
    &:last-child
        margin-bottom: 0
    &--active
        border: #319144 solid 3px
    &__container
        display: flex
        flex-flow: column
        @media (max-width: 1110px)
            width: 100%
            margin-right: 0px
    &__block
        display: flex
        align-items: center
        height: 35px
        @media (max-width: 1110px)
            flex-flow: column
            height: 100%
    &__item
        display: flex
        align-items: center
        margin-right: 20px
        @media (max-width: 1110px)
            margin-right: 0
            width: 100%
            height: 35px
    &__itemName
        text-decoration: none
        color: #000
        font-weight: bold
        display: block
        width: 200px !important
        margin: 0 auto
    &__lable
        font-size: 15px
        font-weight: bold
        margin-right: 10px
        @media (max-width: 1110px)
            width: 100px
    &__value
        font-size: 14px
        padding: 7px
        border-radius: 30px
        background: #f9f9f9
        width: 130px
        font-size: 13px
        text-align: center
        line-height: 16px
        overflow: hidden
        margin: 0 auto
        &--active
            cursor: pointer
        &--time
            background: #d7f5d9
            font-weight: bold
            font-size: 12px
            cursor: default
        &--link
            text-decoration: none
            background: #56ca83
            color: #fff
            font-weight: bold
            width: 100%
        &--linkTable
            text-decoration: none
            display: block
            color: #000000
            background: #d7f5d9
        &--warning
            background: #ffeeee
        &--alert
            background: #fdfde0
        input
            background: none
            border: none
            font-weight: bold
            width: 110px
            text-align: center
    &__button
        margin-top: 10px
        text-align: center
        width: max-content
        @media (max-width: 1110px)
            width: 100%
    &__select
        min-width: 140px

.tableBlock
    background: #fff
    font-size: 13px
    border-collapse: collapse
    width: 100%
    tr
        border-bottom: 2px solid #f9f9f9
        &:last-child
            border-bottom: 0
        &:hover
            background: #ececec
    th
        padding: 10px
    &__td
        padding: 10px
        text-align: center
        &--select
            text-align: unset
        &--id
            cursor: pointer
        //глобальный
        &--category
            .article__select
                min-width: 500px
        a
            text-decoration: none
            color: #000
    &__price
        display: flex
        align-items: center
        width: max-content
    &__priceItem
        font-weight: bold
        margin-left: 10px
        min-width: max-content

    thead
        tr
            background: #f9f9f9
            border-bottom: 0

.publishButtom
    &--update, &--add
        background: #d7f5d9
    &--hide
        background: #f9f9f9
        cursor: default
    &--currentUpdate
        font-weight: bold

.paginationButtons
    margin-bottom: 50px
    display: flex
    align-items: center
    margin-top: 10px
    &__counter
        padding: 7px
        font-size: 12px
        background: #ff8417
        font-weight: bold
        color: #fff
        border-radius: 4px
        width: 30px
        text-align: center
        margin-right: 10px
        margin-left: 10px
