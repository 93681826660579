body
    background: #f4f4f4

*
    outline: none !important

br
    line-height: 3px

ul
    li
        margin-bottom: 5px

input:read-only, textarea:read-only
    background: #f9f9f9
    color: #333
    cursor: default

.mark
    background-color: #ffffa1
    box-shadow: 0 5px 0 #ffffa1,0 -1px 0 #ffffa1

.alert
    color: red

.nolink
    text-decoration: none
    color: #464646 !important

.loader
    position: absolute
    z-index: 1000
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    background-color: rgba(255, 255, 255, 0.76)
    &--full
        height: calc(100% - 65px)
        background-color: unset
    &__item
        width: 200px

.button
    display: inline-block
    border: none
    padding: 7px
    font-size: 12px
    font-weight: bold
    background: #319144
    color: #fff
    cursor: pointer
    white-space: nowrap
    text-decoration: none
    border-radius: 4px
    position: relative
    &--em_input
        margin-top: 10px
    &--ots_left
        margin-left: 10px
    &--ots_right
        margin-right: 10px
    &--ots_top
        margin-top: 10px
    &--ots_bm
        margin-bottom: 10px
    &--past
        background: #e4862e
    &--red
        background: #ff5c5c
    &__bage
        position: absolute
        top: -7px
        right: -5px
        background: #bbb
        width: 18px
        height: 18px
        border-radius: 20px
        display: flex
        justify-content: center
        align-items: center
        font-size: 11px
        &--red
            background: red

.bold
    font-weight: bold

.content
    margin-top: 65px

.element
    background: #fff
    padding: 25px
    margin-bottom: 15px
    max-width: 1100px
    margin: 0 auto
    margin-bottom: 15px
    border-radius: 20px
    &--full
        max-width: 100%
    &--hide
        *
            display: none !important
        .element__title, .element__hiddenElement, .element__titleLeft, .element__titleRight, .element__noActiveTitle--active
            display: flex !important
            margin-bottom: 0
    &__header
        display: flex
        align-items: center
    &__name
        color: #464646
        border-bottom: 1px solid #f2f2f2
        padding-bottom: 10px
        font-weight: bold
        font-size: 16px
    &__title
        display: flex
        font-weight: bold
        margin-bottom: 15px
        font-size: 20px
        @media (max-width: 1110px)
            overflow-x: scroll
            overflow-y: hidden
            max-height: 35px
        &--group
            justify-content: space-between
        &--ost_bm_no
            margin-bottom: 0px
    &__titleLeft
        display: flex
        align-items: center
        @media (max-width: 1110px)
            white-space: nowrap
        &--small
            font-size: 14px
    &__noActiveTitle
        margin-left: 8px
        font-size: 15px
        display: none
        align-items: center
        color: #e65454
        &--active
            display: flex
    &__titleRight
        display: flex
        align-items: center

    &__plan
        background: #fff
        box-shadow: 0 5px 40px rgba(0,0,0,.14),0 1px 5px rgba(0,0,0,.04)
        padding: 15px
        border-radius: 3px
        @media (max-width: 1110px)
            margin-left: -25px
            margin-right: -25px
            width: auto
    &__planItem
        margin-top: 10px
        white-space: pre-line
        &:first-child
            margin-top: 0px
        &--alert
            background: #fdcaca
            padding: 20px
            border-radius: 4px
        &--warning
            background: #fff9cf
            padding: 20px
            border-radius: 4px

    &__countChart
        font-size: 14px
        min-width: 170px
        display: flex
        justify-content: flex-end
        font-size: 13px
        white-space: nowrap
        margin-left: 15px
        color: #464646
    &__hiddenElement
        display: flex
        width: 20px
        min-width: 20px
        height: 20px
        background: #fff
        align-items: center
        justify-content: center
        margin-left: 10px
        border-radius: 4px
        cursor: pointer
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.3)
        color: #464646
        font-size: 13px
        &:last-child
            @media (max-width: 1110px)
                margin-right: 5px
        &--text
            width: auto
            padding: 0px 10px
            font-size: 13px
        &--green
            background: #56ca83
            color: #fff
        &--nolmg
            margin-left: 0px
    &__itemsBlock
        margin-top: 15px
        &--big
            flex-grow: 1
        &--lvl_2
            padding: 20px
            border-radius: 4px
            background: #fff
            box-shadow: 0 5px 40px rgba(0,0,0,.14),0 1px 5px rgba(0,0,0,.04)
    &__imgBlock
        margin-top: 10px
        background: #f9f9f9
        padding: 10px
        overflow: hidden
        border-radius: 4px
        display: flex
        align-items: center
        height: 38px
        input
            width: 180px
        &--active
            height: 81px

    &__imgStatus
        width: 61px
        height: 61px
        border-radius: 4px
        background: #dbdbdb
        margin-right: 14px
        overflow: hidden
    &__imgItem
        width: 100%
        height: 100%
        object-fit: cover

    &__block
        width: 100%
        display: block
        margin-top: 10px
        &:first-child
            margin-top: 0px
        //Глобальный модификатор
        &--ulListing
            display: flex
            flex-flow: row nowrap
            @media (max-width: 1110px)
                flex-flow: column
            .element__column
                &:first-child
                    min-width: 200px
                &:last-child
                    width: 100%
                    margin-left: 10px
                    @media (max-width: 1110px)
                        margin-top: 10px
                        margin-left: 0
        //Глобальный модификатор
        &--ulListing_3
            display: flex
            flex-flow: row nowrap
            @media (max-width: 1110px)
                flex-flow: column
            .element__column
                &:nth-child(1)
                    min-width: 160px
                &:nth-child(2)
                    min-width: 160px
                    margin-left: 10px
                    @media (max-width: 1110px)
                        margin-left: 0
                        margin-top: 10px
                &:last-child
                    width: 100%
                    margin-left: 10px
                    @media (max-width: 1110px)
                        margin-left: 0
                        margin-top: 10px
        //Глобальный модификатор
        &--oneLine
            display: flex
            flex-flow: row nowrap
            .element__column
                width: 100%
                margin-right: 10px
                &:last-child
                    margin-right: 0px
            .element__column--videoImg
                width: 82px
            .element__textarea
                height: 38px
            @media (max-width: 1110px)
                flex-flow: column
                .element__column
                    margin-right: 0px
                    margin-top: 10px
                    &:first-child
                        margin-top: 0px
            .element__itemsBlock
                margin-top: 5px
                @media (min-width: 1110px)
                    margin-right: 20px
                &:last-child
                    @media (min-width: 1110px)
                        margin-right: 0
        &--editor
            margin-top: 0px

    &__items
        margin-top: 10px
    &__item
        margin-top: 10px
        background: #fff
        box-shadow: 0 5px 40px rgba(0,0,0,.14),0 1px 5px rgba(0,0,0,.04)
        padding: 15px
        border-radius: 4px
        margin-top: 10px
        @media (max-width: 1110px)
            margin-left: -25px
            margin-right: -25px
            width: auto
            .element__item
                margin-left: -10px
                margin-right: -10px
                .element__item
                    margin-left: 0px
                    margin-right: 0px
        &:first-child
            margin-top: 0
        p
            width: 100%
            margin: 0
            padding: 10px
            background: #f9f9f9
            margin-bottom: 10px
            border-radius: 4px
            &:last-child
                margin-bottom: 0px
        //Глобальный модификатор
        &--twoListing
            padding: 20px
            border-radius: 4px
        &--hide
            *
                display: none !important
            .element__title, .element__hiddenElement, .element__titleLeft, .element__titleRight
                display: flex !important
                margin-bottom: 0
        &--oneLine
            @media (min-width: 1110px)
                display: flex
                align-items: center
                .element__block
                    margin-top: 0
                    margin-right: 10px
                    &:last-child
                        margin-right: 0
                p
                    &:last-child
                        margin-bottom: 10px
        &--video
            .element__block
                &:first-child
                    width: 82px
                    display: flex
                    align-items: center
        &--noTopOtstup
            margin-top: 0px
        &--noBmOtstup
            margin-bottom: 0px
            p
                margin-bottom: 0px
                &:last-child
                    margin-bottom: 0px

        &--noBg
            box-shadow: none
            padding: 0
        &--imported
            border: solid 3px rgba(228, 134, 46, 0.52)
        &--editor
            padding-top: 20px
            padding-left: 35px
            padding-right: 35px
    &__itemName
        font-weight: bold
        border-bottom: 1px solid #f2f2f2
        width: 100%
        display: block
        padding-bottom: 10px

    &__blockName
        font-size: 14px
        font-weight: bold
        margin-top: 10px
        display: flex
        color: #464646
        justify-content: space-between
        &:first-child
            margin-top: 0
        &--bmOts
            margin-bottom: 10px
        &--rmg
            margin-right: 10px
    &__input
        font-family: arial, sans-serif
        width: 100%
        height: 38px
        margin-top: 10px
        padding: 5px
        font-size: 16px
        line-height: 20px
        border-color: hsl(0, 0%, 90%)
        border-radius: 4px
        border-style: solid
        border-width: 1px
    &__select
        margin-top: 10px
    &__textarea
        resize: vertical
        font-family: arial, sans-serif
        width: 100%
        margin-top: 10px
        border: solid 1px #e4e4e4
        border-radius: 4px
        height: 75px
        min-height: 50px
        padding: 5px
        font-size: 16px
        line-height: 20px
        display: block
        &--x2
            min-height: 111px
        &--mini
            min-height: 38px

    &__videoImg
        width: 82px

    &__radioButtons
        margin-top: 10px
    &__blockHiddens
        display: flex
        flex-flow: row nowrap
        .element__blockHidden
            margin-right: 10px
    &__hidden
        display: none
        &--active
            display: block
            margin-top: 10px
    &__hiddenButton
        background: #b7b7b7
        margin-top: 10px

    &__hiddenUsers
        margin-top: 10px

.result
    &__intro
        background: #f9f9f9
        padding: 20px
        text-align: center
        font-weight: bold
        font-size: 17px
        &--top
            margin-top: 15px
    &__product
        background: #fffaf5
        padding: 2px 15px
        margin-bottom: 10px
        min-height: 150px
        &--imported
            border: solid 3px rgba(228, 134, 46, 0.52)
            margin-top: 5px
            border-radius: 4px
            padding: 10px
    &__productImgWraper
        width: 120px
        height: 120px
        border-radius: 4px
        margin-right: 14px
        overflow: hidden
        float: left
        margin-top: 15px
        margin-bottom: 15px
        @media (max-width: 350px)
            width: 100%
            height: 100%
            margin-right: 0
            float: none
    &__productImg
        width: 100%
        height: 100%
        object-fit: cover
    &__videoItems
        display: flex
        flex-flow: row wrap
        margin-top: 10px
    &__videoImg
        width: 85px
        height: 64px
        background: #d8d8d8
        margin-bottom: 10px
        margin-right: 10px

.modal
    display: none
    &--active
        display: block
    &__overlay
        position: fixed
        overflow: auto
        left: 0
        top: 0
        width: 100%
        height: 100%
        background-color: rgba(0,0,0,0.5)
    &__inner
        position: absolute
        left: 50%
        top: 50%
        margin-left: -200px
        margin-top: -50px
    &__container
        background-color: #fff
        width: 400px
        height: 100px
        padding: 20px
        text-align: center

.none
    display: none

.textCenter
    text-align: center

.chekTextBlock
    &__title
        margin-top: 15px
        font-size: 17px
        font-weight: bold
    &__ul
        margin-top: 10px

.authBlock
    display: flex
    justify-content: center
    &__container
        max-width: 300px
        width: 100%
        display: flex
        flex-flow: column
        background: #fff
        padding: 15px
        border-radius: 4px
        align-items: center
    &__inputs
        display: flex
        flex-flow: column
        width: 100%
        padding: 10px
        input
            width: 100%
            display: block
            margin-bottom: 10px
            &:last-child
                margin-bottom: 0

.editorBlock
    max-width: 850px
    background: #fff
    margin: 0 auto
    border-radius: 20px
    margin-bottom: 15px
    &--scroll
        overflow-x: scroll
    &--padding
        padding: 0 20px
    &__fullscreenButtonBlock
        display: flex
        padding-top: 15px
        justify-content: right
        border-bottom: 1px solid #f0f0f0
        padding-bottom: 10px
        margin-bottom: 10px
        margin-right: auto
        margin-left: auto
        max-width: 750px
    &__fullscreenButton
        display: block
        font-size: 14px
        cursor: pointer
        margin-right: 15px
    &__editorHeader
        font-size: 30px
        font-weight: bold
        padding-top: 10px
        padding-bottom: 10px
        margin-left: 25px
        max-width: 750px
        margin: 0 auto
    .editorWrapper
        max-width: 900px
        margin: 0 auto
        &--readOnly
            background: #f9f9f9
            color: #333
            cursor: default
            padding: 20px
    .ce-block__content, .ce-toolbar__content
        max-width: 750px !important
    .codex-editor__redactor
        padding-bottom: 50px !important
    &__fullscreen.fullscreen-enabled
        background: #fff
        overflow: scroll
        .codex-editor__redactor
            padding-bottom: 1000px !important
    .carousel-removeBtn
        display: inline-flex !important
    .ce-toolbox
        @media (max-width: 650px)
            overflow-x: scroll
            max-width: calc(100vw - 117px)
            display: flex
    .cdx-input[data-placeholder]::before
        position: absolute !important
    a
        color: #2b77ee

.react-datepicker-popper
    z-index: 100 !important

.resultTable
    border-collapse: collapse
    td, th
        border: 1px solid #e9e9e9
        padding: 10px
        text-align: center
    &__img
        width: 50px
        height: 50px
        border-radius: 4px
        overflow: hidden
        display: inline-block
    &__imgItem
        width: 100%
        height: 100%

.tableScroll
    overflow-x: scroll

.linkBlock
    margin-right: 10px

.gamePlace
    height: calc(100vh - 69px)
    font-size: 15px
    &__buttons
        height: 40px
        display: block
        float: right
        margin-right: 20px
        @media ( max-width: 900px )
            display: none

    .fullscreen
        height: calc(100% - 40px)

.react-datepicker__input-container
    input
        font-size: 11px !important

.modal
    &__close
        width: 100%
        text-align: right
        font-size: 14px
        font-weight: bold
        margin-bottom: 5px
        cursor: pointer
    &__title
        font-size: 17px
        margin-bottom: 15px
