.header
  background: #eeeeee
  font-size: 15px
  position: fixed
  top: 0
  width: 100%
  height: 50px
  display: flex
  flex-flow: row nowrap
  padding: 0 20px
  z-index: 10
  overflow: hidden
  &__inner
    display: flex
    flex-flow: row nowrap
    width: 100%
    height: 100px
    justify-content: space-between
    overflow-x: scroll
    overflow-y: hidden
    &::-webkit-scrollbar
      width: 0
  &__left
    height: 50px
    display: flex
    align-items: center
  &__right
    display: flex
    flex-flow: column
    justify-content: space-evenly
    align-items: flex-end
    height: 50px
    @media (max-width: 1110px)
      flex-flow: row
      align-items: center
      margin-left: 15px
  &__editStatus
    width: 15px
    margin-right: 10px
  &__name
    font-weight: bold
    font-size: 16px
    margin-right: 20px
    white-space: nowrap
  &__link
    margin-right: 10px
    white-space: nowrap
    text-decoration: none
    color: #464646
    font-weight: bold
    border-right: 1px solid #dfdfdf
    padding-right: 10px
    margin-right: 10px
    &:hover
      color: #000
    &:last-child
      border: none
  &__textItem
    white-space: nowrap
    &:last-child
      @media (max-width: 1110px)
        margin-left: 10px
